<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({
      fields: {
        image: {
          type: 'media',
          label: 'Image',
          breakpoints: { default: { width: 680, height: 453 } },
        },
        title: { type: 'text', label: 'Title' },
        description: { type: 'textarea', label: 'Description' },
        link: { type: 'link', label: 'Link' },
      },
      name: { label: 'Seadust Left Align Content Slide', group: 'Seadust', layouts: ['Seadust'] },
      templates: [
        {
          label: 'Seadust Left Align Content Slide',
          fields: {
            description: { value: '' },
            image: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/seadust\/2023\/rooms\/seadust-cancun-family-suite-king-04.jpg',
            },
            link: {
              value: {
                text: '',
                target: '_self',
                relationship: null,
                href: '\/new-page',
              },
            },
            title: { value: '' },
          },
        },
      ],
    })
  },
}
</script>

<template>
  <div class="">
    <div class="">
      <VoixMedia
        :field="fields.image"
        class="rooms-cancer__img"
        alt="Luxury suite Seadust Cancun Family. Resort"
        title="Luxury suite Seadust Cancun Family. Resort"
        height="453"
        width="680"
      />
    </div>

    <div class="flex flex-col space-y-4 py-4">
      <p class="text-3xl font-subtitle text-color-primary">
        {{ fields.title.value }}
      </p>

      <p class="">
        {{ fields.description.value }}
      </p>

      <a class="" :href="fields.link.value?.href" title="See Suite">
        {{ fields.link.value?.text }}
      </a>
    </div>
  </div>
</template>
